(function ($) {
  Drupal.behaviors.siteSearchResultsV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var $template = $('.js-site-search-results', context);
      var bps = Unison.fetch.all();
      var currentWidth = $(window).width();
      var isMobileWidth = parseInt(currentWidth, 10) < parseInt(bps.large, 10);

      $(window)
        .off('resize.searchResults')
        .on(
          'resize.searchResults',
          _.debounce(function () {
            currentWidth = $(window).width();
            isMobileWidth = parseInt(currentWidth, 10) < parseInt(bps.large, 10);
          }, 100)
        );

      $('.js-site-search-results-close', $template)
        .once()
        .on('click.searchResults', function () {
          self.resetSearchResults($template);
        });

      $template.once().on('clickoutside.searchResults', function () {
        if (!isMobileWidth) {
          self.resetSearchResults($template);
        }
      });

      $(document).on(
        'scroll.searchResults',
        _.debounce(function () {
          if (!isMobileWidth) {
            self.resetSearchResults($template);
          }
        }, 100)
      );

      $(document).on('reset.searchResults', function () {
        self.resetSearchResults($template);
      });
    },
    resetSearchResults: function ($template) {
      var $typeahead = $('.js-typeahead-wrapper', $template);

      if ($typeahead.hasClass('hidden')) {
        return;
      }
      $(document).trigger('resetPredictiveSearch');
      $typeahead.addClass('hidden');
      $('.js-product-results', $template).empty();
      $('.js-see-results', $template).hide();
      $('body').removeClass('search-active');
    }
  };
})(jQuery);
